import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import watchlist from 'lib/api/watchlist';
import companyApi from 'lib/api/company';
import searchApi from 'lib/api/search';
import follow from 'lib/models/company/follow';
import { CompanyChartState } from './company.types';
import { CommonPayload } from '../../../@types/action-payload';
import { FollowUserParams, FollowUserPayload } from './watchlist/slice.types';

// States can be updated through follow/unfollow
export const STATE_SUGGESTION = 'suggestion';
export const STATE_SEARCH = 'search';
export const STATE_DETAIL = 'detail';

const defaultSummary = {
  brokers_buy: [],
  brokers_sell: [],
  symbol: '',
};

// Initial state
const initialState = {
  detail: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
  search: {
    data: [],
    isLoading: false,
    error: null,
    message: '',
  },
  chart: {
    data: {},
    popoverData: {},
    isLoading: false,
    error: null,
    message: '',
  } as CompanyChartState,
  postFollowCompany: {
    data: [],
    isLoading: false,
    error: null,
    message: '',
  },
  postUnfollowCompany: {
    data: follow.schema,
    isLoading: false,
    error: null,
    message: '',
  },
  contact: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
  brokerSummary: {
    data: {
      detector: {},
      summary: defaultSummary,
      from: '',
      to: '',
    },
    isLoading: false,
    error: null,
    message: '',
  },
};

type CompanyState = typeof initialState;

const companySelector = (state) => state.entities.company;
export const selectors = createSelector(
  companySelector,
  (company: CompanyState) => ({
    ...company,
  }),
);

// Action types
const CONTEXT = '@redux/company';

const actionType = {
  FETCH_SEARCH: `${CONTEXT}/FETCH_SEARCH`,
  CLEAR_SEARCH: `${CONTEXT}/CLEAR_SEARCH`,
  POST_FOLLOW_COMPANY: `${CONTEXT}/POST_FOLLOW_COMPANY`,
  POST_UNFOLLOW_COMPANY: `${CONTEXT}/POST_UNFOLLOW_COMPANY`,
  GET_COMPANY_DETAIL: `${CONTEXT}/GET_COMPANY_DETAIL`,
  GET_COMPANY_CHART: `${CONTEXT}/GET_COMPANY_CHART`,
  GET_COMPANY_CONTACT: `${CONTEXT}/GET_COMPANY_CONTACT`,
  FOLLOW_USER: `${CONTEXT}/FOLLOW_USER`,
  GET_BANDAR_DETECTOR: `${CONTEXT}/GET_BANDAR_DETECTOR`,
};

interface BasePayload<T = any> extends CommonPayload<T> {
  symbol?: string;
}

interface followCompanyParams {
  watchlistId: number;
  companyId: number;
  stateToUpdate: string;
}

interface companyChartParams {
  symbol: string;
  timeframe: string;
  isPopover?: boolean;
}

interface GetBandarDetectorParams {
  symbol: string;
  params: {
    from?: Date;
    to?: Date;
  };
}

type GetBandarDetectorPayload = CommonPayload

// symbol, timeframe

// Effects
// Side effect from external
export const effects = {
  getSearchCompany: createAsyncThunk<BasePayload, string>(
    actionType.FETCH_SEARCH,
    async (keyword) => {
      try {
        const response = await searchApi.getSearchCompany(keyword);

        if (response.data) {
          const { data, message, error } = response.data;

          if (error) {
            return { message, error };
          }

          const sanitizedData = [...data.company];

          return { data: sanitizedData, message };
        }

        throw new Error('Attempt to fetch Search company Failed');
      } catch (error) {
        return { error };
      }
    },
  ),

  getCompanyDetail: createAsyncThunk<BasePayload, string>(
    actionType.GET_COMPANY_DETAIL,
    async (symbol) => {
      try {
        const response = await companyApi.getCompanyInfo(symbol);

        if (!response.data) {
          throw new Error('Attempt to get company detail failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, symbol, message };
      } catch (error) {
        return { error };
      }
    },
  ),

  getCompanyChart: createAsyncThunk<BasePayload, companyChartParams>(
    actionType.GET_COMPANY_CHART,
    async ({ symbol, timeframe, isPopover = false }) => {
      try {
        const response = await companyApi.getCompanyChart(symbol, timeframe);

        if (!response.data) {
          throw new Error('Attempt to get company chart failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { message, error };
        }

        return { data, message, symbol, isPopover };
      } catch (error) {
        return { error };
      }
    },
  ),

  // Update follow status company, given parameters
  // Watchlist ID, Company ID,
  // and state to be updated (search | suggestion | etc)
  postFollowCompany: createAsyncThunk<BasePayload, followCompanyParams>(
    actionType.POST_FOLLOW_COMPANY,
    async ({ watchlistId, companyId, stateToUpdate = STATE_SUGGESTION }) => {
      try {
        const response = await watchlist.postFollowCompany(
          watchlistId,
          companyId,
        );

        if (response.data) {
          const { data, message, error_type: error } = response.data;

          if (error) {
            return { error, message };
          }

          // choose the first index because the data response is array
          const sanitizedData = data[0];

          return {
            data: sanitizedData,
            message,
            stateToUpdate,
          };
        }

        throw new Error('Attempt to post Follow company Failed');
      } catch (error) {
        return { error };
      }
    },
  ),

  // Update unfollow status company, given parameters
  // Watchlist ID, Company ID,
  // and state to be updated (search | suggestion | etc)
  postUnfollowCompany: createAsyncThunk<BasePayload, followCompanyParams>(
    actionType.POST_UNFOLLOW_COMPANY,
    async ({ watchlistId, companyId, stateToUpdate = STATE_SUGGESTION }) => {
      try {
        const response = await watchlist.postUnfollowCompany(
          watchlistId,
          companyId,
        );

        if (response.data) {
          const { data, message, error_type: error } = response.data;

          if (error) {
            return { error, message };
          }

          return { data, message, stateToUpdate };
        }

        throw new Error('Attempt to post Follow company Failed');
      } catch (error) {
        return { error };
      }
    },
  ),

  getCompanyContact: createAsyncThunk<BasePayload, string>(
    actionType.GET_COMPANY_CONTACT,
    async (symbol) => {
      try {
        const response = await companyApi.getCompanyContact(symbol);

        if (!response.data) {
          throw new Error('Attempt to get company detail failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, symbol, message };
      } catch (error) {
        return { error };
      }
    },
  ),

  // TODO integrate postFollowUser to watchlist followUser
  postFollowUser: createAsyncThunk<FollowUserPayload, FollowUserParams>(
    actionType.FOLLOW_USER,
    async ({ username, userid, type = 'FOLLOW' }) => {
      try {
        if (!userid) {
          throw new Error('User id is empty');
        }

        let targetAPI = watchlist.postFollowUser;

        if (type === 'UNFOLLOW') {
          targetAPI = watchlist.postUnfollowUser;
        }

        const response = await targetAPI(userid);

        if (!response.data) {
          throw new Error('Attempt to follow user failed');
        }
        const { error_type: error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, type, username, userid };
      } catch (error) {
        return { error };
      }
    },
  ),
  getBandarDetector: createAsyncThunk<GetBandarDetectorPayload, GetBandarDetectorParams>(
    actionType.GET_BANDAR_DETECTOR,
    async ({ symbol, params }) => {
    try {
      const response = await companyApi.getMarketDetector(symbol, params);

      if (!response.data) {
        throw new Error('Attempt to get market detector failed!');
      }

      const { data, error, message } = response.data;

      if (error) {
        return { error, message };
      }

      return { data, message };
    } catch (error) {
      return { error };
    }
  }),
};

const reducers = {
  clearSearch: (state) => {
    state.search.data = [];
  },
};

const extraReducers = (builder) => {
  builder
    .addCase(effects.getSearchCompany.pending, (state: CompanyState) => {
      state.detail.isLoading = true;
      state.detail.error = null;
    })
    .addCase(
      effects.getSearchCompany.fulfilled,
      (state: CompanyState, action: PayloadAction<any>) => {
        const { data, message, error } = action.payload;

        if (error) {
          state.search.error = error;
        } else {
          state.search.data = data;
        }

        state.search.message = message;
        state.search.isLoading = false;
      },
    )
    .addCase(
      effects.getSearchCompany.rejected,
      (state: CompanyState, action: PayloadAction<any>) => {
        state.search.error = action.payload.error;
        state.search.isLoading = false;
      },
    )

    .addCase(effects.getCompanyDetail.pending, (state: CompanyState) => {
      state.detail.isLoading = true;
      state.detail.error = null;
    })
    .addCase(
      effects.getCompanyDetail.fulfilled,
      (state: CompanyState, action: PayloadAction<any>) => {
        const { error, message, data, symbol } = action.payload;
        if (error) {
          state.detail.error = error;
        } else {
          state.detail.data[symbol] = data;
          state.detail.error = null;
        }

        state.detail.isLoading = false;
        state.detail.message = message;
      },
    )
    .addCase(
      effects.getCompanyDetail.rejected,
      (state: CompanyState, action: PayloadAction<any>) => {
        state.detail.error = action.payload.error;
        state.detail.isLoading = false;
      },
    )

    .addCase(effects.getCompanyChart.pending, (state: CompanyState) => {
      state.chart.isLoading = true;
      state.chart.error = null;
    })
    .addCase(
      effects.getCompanyChart.fulfilled,
      (state: CompanyState, action: PayloadAction<any>) => {
        const { error, message, data, symbol, isPopover } = action.payload;
        if (error) {
          state.chart.error = error;
        } else {
          // data might be null, e.g. CPO timeframe 1d
          const newData = data ? { ...data } : null;
          // Inject Previous Price to 'today' prices
          // https://stockbit.atlassian.net/browse/IDT-501
          if (
            data &&
            data.timeframe === 'today' &&
            data.prices.length &&
            data.previous
          ) {
            newData.prices = [
              {
                date: '0',
                formatted_date: '',
                xlabel: '0',
                value: data.previous.toString(),
                percentage: '0.00',
                change: 0,
              },
              ...data.prices,
            ];
          }
          // Mini chart
          state.chart.data[symbol] = newData;

          // Popover chart
          if (isPopover) {
            state.chart.popoverData[symbol] = newData;
          }

          state.chart.error = null;
        }

        state.chart.isLoading = false;
        state.chart.message = message;
      },
    )
    .addCase(
      effects.getCompanyChart.rejected,
      (state: CompanyState, action: PayloadAction<any>) => {
        state.chart.error = action.payload.error;
        state.chart.isLoading = false;
      },
    )

    .addCase(effects.postFollowCompany.pending, (state: CompanyState) => {
      state.postFollowCompany.isLoading = true;
      state.postFollowCompany.error = null;
    })
    .addCase(
      effects.postFollowCompany.fulfilled,
      (state: CompanyState, action: PayloadAction<any>) => {
        const STATUS_FOLLOWING = 1;
        const { error, message, data, stateToUpdate } = action.payload;

        if (error) {
          state.postFollowCompany.error = error;
        } else {
          state.postFollowCompany.data = data;

          // TODO: Move to new function to wrap this, if allowed
          // update state search
          // find company with `id` == `data.companyId`
          // update followed value to `1`
          const { companyid, symbol } = data;
          const newDataState = state.search.data;
          const changedDataIndex = newDataState.findIndex(
            ({ id }) => Number(id) === Number(companyid),
          );

          // Do if else to only change specific data state based on
          // flag `stateToUpdate`
          if (stateToUpdate === STATE_SEARCH && changedDataIndex > -1) {
            state.search.data[changedDataIndex].is_following =
              !!STATUS_FOLLOWING;
          }

          if (stateToUpdate === STATE_DETAIL) {
            state.detail.data[symbol].followed = STATUS_FOLLOWING;
          }
        }

        state.postFollowCompany.message = message;
        state.postFollowCompany.isLoading = false;
      },
    )
    .addCase(
      effects.postFollowCompany.rejected,
      (state: CompanyState, action: PayloadAction<any>) => {
        state.postFollowCompany.error = action.payload.error;
        state.postFollowCompany.isLoading = false;
      },
    )

    .addCase(effects.postUnfollowCompany.pending, (state: CompanyState) => {
      state.postUnfollowCompany.isLoading = true;
      state.postUnfollowCompany.error = null;
    })
    .addCase(
      effects.postUnfollowCompany.fulfilled,
      (state: CompanyState, action: PayloadAction<any>) => {
        const STATUS_UNFOLLOWING = 0;
        const { error, message, data, stateToUpdate } = action.payload;

        if (error) {
          state.postUnfollowCompany.error = error;
        } else {
          state.postUnfollowCompany.data = data;

          // TODO: Move this to new function to wrap it, if allowed
          // update state
          // find company with `id` == `data.companyId`
          // update followed value to `1`
          const { companyid, symbol } = data;
          const newDataState = state.search.data;
          const changedDataIndex = newDataState.findIndex(
            ({ id }) => Number(id) === Number(companyid),
          );

          if (stateToUpdate === STATE_SEARCH && changedDataIndex > -1) {
            state.search.data[changedDataIndex].is_following =
              !!STATUS_UNFOLLOWING;
          }

          if (stateToUpdate === STATE_DETAIL) {
            state.detail.data[symbol].followed = STATUS_UNFOLLOWING;
          }
        }

        state.postUnfollowCompany.message = message;
        state.postUnfollowCompany.isLoading = false;
      },
    )
    .addCase(
      effects.postUnfollowCompany.rejected,
      (state: CompanyState, action: PayloadAction<any>) => {
        state.postUnfollowCompany.error = action.payload.error;
        state.postUnfollowCompany.isLoading = false;
      },
    )
    .addCase(effects.getCompanyContact.pending, (state: CompanyState) => {
      state.contact.isLoading = true;
      state.contact.error = null;
    })
    .addCase(
      effects.getCompanyContact.fulfilled,
      (state: CompanyState, action: PayloadAction<any>) => {
        const { error, message, data, symbol } = action.payload;
        if (error) {
          state.contact.error = error;
        } else {
          state.contact.data[symbol] = data;
          state.contact.error = null;
        }

        state.contact.isLoading = false;
        state.contact.message = message;
      },
    )
    .addCase(
      effects.getCompanyContact.rejected,
      (state: CompanyState, action: PayloadAction<any>) => {
        state.contact.error = action.payload.error;
        state.contact.isLoading = false;
      },
    )
    .addCase(
      effects.postFollowUser.fulfilled,
      (state: CompanyState, action: PayloadAction<any>) => {
        const { data, error, userid, type, username: symbol } = action.payload;
        if (!error) {
          const activeSymbol = state.contact.data[symbol];

          if (activeSymbol) {
            const idx = activeSymbol.findIndex(
              (item) => item.id === userid,
            );

            if (idx !== -1) {
              const FOLLOWING = type === 'FOLLOW' ? 1 : 0;
              // If follow specific child, also add following to all watchlist
              // Update the selected wathclist item
              state.contact.data[symbol][idx].is_followed = FOLLOWING;
            }
          }
        }
      },
    )
    .addCase(
      effects.getBandarDetector.pending,
      (state: CompanyState) => {
        state.brokerSummary.isLoading = true;
        state.brokerSummary.error = null;
      },
    )
    .addCase(
      effects.getBandarDetector.fulfilled,
      (
        state: CompanyState,
        action: PayloadAction<GetBandarDetectorPayload>,
      ) => {
        const { data = {}, error, message } = action.payload;

        if (error) {
          state.brokerSummary.error = error;
        }

        if (!data.bandar_detector || !data.broker_summary) {
          state.brokerSummary.data.detector = {};
          state.brokerSummary.data.summary = defaultSummary;
          state.brokerSummary.data.from = '';
          state.brokerSummary.data.to = '';

          state.brokerSummary.error = null;
        }

        if (data.bandar_detector && data.broker_summary) {
          state.brokerSummary.data.detector = data.bandar_detector;
          state.brokerSummary.data.summary = data.broker_summary;
          state.brokerSummary.data.from = data.from;
          state.brokerSummary.data.to = data.to;

          state.brokerSummary.error = null;
        }

        state.brokerSummary.message = message;
        state.brokerSummary.isLoading = false;
      },
    )
    .addCase(
      effects.getBandarDetector.rejected,
      (state: CompanyState, action) => {
        state.brokerSummary.error = action.payload.error;
        state.brokerSummary.isLoading = false;
      },
    );
};

// Create redux slice
const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers,
  extraReducers,
});

export default companySlice;
